import './style.scss';



const Contact = (props) => {
    


    return (
        <div className="link__contact__wrap">
            <div className="link__contact__content">
                <div className="link__contact__info">
                    <div className="link__contact__card__background"></div>
                    <div className="link__contact__card">
                        <div className="link__contact__input">
                            <input type="text" placeholder="Full Name"></input>
                        </div>
                        <div className="link__contact__input">
                            <input type="email" placeholder="E-mail"></input>
                        </div>
                        <div className="link__contact__input">
                            <input type="email" placeholder="Phone"></input>
                        </div>
                        <div className="link__contact__input">
                            <input type="text" placeholder="Leave us a message"></input>
                        </div>
                        <div className="link__contact__send">
                            <span>Send</span>
                        </div>
                    </div>
                </div>
                <div className="link__company__info">
                    <div className="link__company__title">
                        <span>GET IN TOUCH</span>
                    </div>
                    <div className="link__company__detail">
                        <span>Lanner Electronics Inc.</span>
                        <span>+886-2-8692-6060</span>
                        <span> <a href="mailto:contact@lannerinc.com">contact@lannerinc.com</a></span>
                        <span>7F, No.173, Section 2 Datong Road, XiZhi District, New Taipei City, 221, Taiwan</span>
                        <div className="link__company__find__us">
                            <span>Find us:&nbsp;&nbsp;</span>
                            <span className="link__find__fb">
                                <a href="https://www.facebook.com/lannerinc" target="_blank" rel="noreferrer">Facebook</a>
                            </span>
                            <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                            <span className="link__find__yt">
                                <a href="https://www.youtube.com/c/Lannerinc" target="_blank" rel="noreferrer">YouTube</a>
                            </span>
                            <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                            <span className="link__find__ln">
                                <a href="https://tw.linkedin.com/company/lanner-electronics-inc-" target="_blank" rel="noreferrer">LinkedIn</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="link__copyright">
                    <span>Copyright © 2022 Lanner Electronics Inc. All Rights Reserved.</span>
                </div>
            </div>
        </div>
    );
};

export default Contact;