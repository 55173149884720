import { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import Main from "./Containers/Main";
import Subjects from './Components/Subjects';
import Project from './Components/Project';
import PageNotFound from "./Components/PageNotFound";

function App() {

  const [firstTierRouter, setFirstTierRouter] = useState('');
  const [secondTierRouter, setSecondTierRouter] = useState('');

  const setRouter = () => {
    let currentUrl = window.location.pathname;
    let pathArr = currentUrl.split("/");
    let projectPath = pathArr.pop();
    let subjectPath = pathArr.pop();
    setFirstTierRouter(subjectPath);
    setSecondTierRouter(projectPath);
    localStorage.setItem('subj', subjectPath);
    localStorage.setItem('proj', projectPath);
  };

  useEffect(() => {
    let currentUrl = window.location.pathname;
    let pathArr = currentUrl.split("/");
    let projectPath = pathArr.pop();
    let subjectPath = pathArr.pop();
    let proj = localStorage.getItem('proj');
    let subj = localStorage.getItem('subj');
    if(projectPath === proj) {
      setSecondTierRouter(projectPath);
    };
    if(subjectPath === subj) {
      setFirstTierRouter(subjectPath);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact path="/"
          render={() =>
            <Main/>
          }
        />
        <Route
          exact path="/manageability"
          render={() =>
            <Subjects 
              title={'manageability'} 
              content={[
                ['Capsulated BIOS update(BMC)', 'capulated-bios-update'],
                ['RedFish BIOS', 'redfish-bios'],
                ['HW Inventory(to BMC)', 'hw-inventory'],
                ['Switch Automotive', 'switch-automotive'],
                ['RedFish BIOS Config', 'redfist-bios-config'],
                ['CScript', 'cscript'],
                ['SEL Download', 'sel-download'],
                ['CMX SNMP V3', 'cmx-snmp-v3'],
                ['Dynamic Fan Curve', 'dynamic-fan-curve'],
                ['SNMP MiBs Trap', 'snmp-mibs-trap'],
                ['SNMP Windows', 'snmap-windows'],
                ['Gen 3 Bypass-SE & Ignition(2nd Edition)', 'gen-3-bypass-se'],
                ['DMS Solution OOB', 'dms-solution-oob'],
                ['Proactive Daemon Service', 'proactive-daemon-service'],
              ]}
              cubeColor={'linear-gradient(135deg, #1489a3 25%, #f20c0c)'}
              setRouter={setRouter}
            />
          }
        />
        <Route
          exact path="/reliability"
          render={() =>
            <Subjects 
              title={'reliability'} 
              content={[
                ['Dual BIOS Gen2', 'dual-bios-gen2'],
                ['BIOS Top Swap', 'bios-top-swap']
              ]}
              cubeColor={'linear-gradient(135deg, #14a367 25%, #bc0cf2)'}
              setRouter={setRouter}
            />
          }
        />
        <Route
          exact path="/security"
          render={() =>
            <Subjects 
              title={'security'} 
              content={[
                ['PFR Support', 'pfr-support'],
                ['Intel CVE Updates', 'intel-cve-updates']
              ]}
              cubeColor={'linear-gradient(135deg, #b17315 25%, #1499a3)'}
              setRouter={setRouter}
            />
          }
        />
        <Route
          exact path="/performance"
          render={() =>
            <Subjects 
              title={'performance'} 
              content={[
                ['Force PXE Boot', 'force-pxe-boot'],
                ['Multi default-value BIOS', 'multi-defaul-value-bios']
              ]}
              cubeColor={'linear-gradient(135deg, #2514a3 15%, #14a35b)'}
              setRouter={setRouter}
            />
          }
        />
        <Route
          exact path="/development"
          render={() =>
            <Subjects 
              title={'development'} 
              content={[
                ['SONiC (Barefoot)', 'sonic-barefoot'],
                ['Switch SDK V1.5', 'switch-sdk-v1.5'],
                ['Switch Autotest', 'switch-autotest'],
                ['PSP 3.0 w CWE (FlawFinder)', 'psp-3.0-w-cwe'],
                ['SONiC (Broadcom)', 'sonic-broadcom'],
                ['Python API for Lanner PSP', 'python-api-for-lanner-psp'],
                ['Lanner PSP', 'lanner-psp']
              ]}
              cubeColor={'linear-gradient(135deg, #a33a14 25%, #e0eb0d)'}
              setRouter={setRouter}
            />
          }
        />
        <Route
          exact path="/connectivity"
          render={() =>
            <Subjects 
              title={'connectivity'} 
              content={[
                ['Connectivity Manager', 'connectivity-manager']
              ]}
              cubeColor={'linear-gradient(135deg, #e9f7f8 25%, #14a38e)'}
              setRouter={setRouter}
            />
          }
        />
        {/* second tier routers */}
        <Route
          exact path="/manageability/snmp-mibs-trap"
          render={() =>
            <Project 
              title={'SNMP MiBs Trap'}
              color={'linear-gradient(135deg, #1489a3 25%, #f20c0c)'}
              docs={[
                {name: 'SNMP MiBs Trap', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/SNMP_MiBs_Trap_20220609.pdf'}
              ]}
              photos={[
                {name: 'NCA-5220', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-5220_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-5220'},
                {name: 'NCA-6210', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-6210C_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-6210'},
              ]}
            />
          }
        />
        <Route
          exact path="/reliability/dual-bios-gen2"
          render={() =>
            <Project 
              title={'Dual BIOS Gen2'}
              color={'linear-gradient(135deg, #14a367 25%, #bc0cf2)'}
              docs={[
                {name: 'Dual BIOS Gen2', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/Dual+BIOS+Gen2+Introduction.pdf'}
              ]}
              photos={[
                {name: 'NCA-6520', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-6520_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-6520'},
                {name: 'NCA-2520', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-2520_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-2520'},
                {name: 'NCA-4030', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-4030_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-4030'},
                {name: 'NCA-6120', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/NCA-6110_angle1.png', link: 'https://lannerinc.com/products/network-appliances/x86-rackmount-network-appliances/nca-6120'}
              ]}
            />
          }
        />
        <Route
          exact path="/development/switch-sdk-v1.5"
          render={() =>
            <Project 
              title={'Switch SDK V1.5'}
              color={'linear-gradient(135deg, #a33a14 25%, #e0eb0d)'}
              docs={[
                {name: 'LSA Switch SDK V1.5', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/Switch+SDK+V1.5_220220609.pdf'}
              ]}
              photos={[
                {name: 'HTCA-6200', pic: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/images/product/HTCA-6200A_front.png', link: 'https://lannerinc.com/products/telecom-datacenter-appliances/hybridtca-platforms/htca-6200'},
              ]}
            />
          }
        />
        <Route
          exact path="/development/lanner-psp"
          render={() =>
            <Project 
              title={'Lanner PSP'}
              color={'linear-gradient(135deg, #a33a14 25%, #e0eb0d)'}
              project={'psp'}
              docs={[]}
              photos={[]}
            />
          }
        />
        <Route
          exact path="/development/python-api-for-lanner-psp"
          render={() =>
            <Project 
              title={'Python API for Lanner PSP'}
              color={'linear-gradient(135deg, #a33a14 25%, #e0eb0d)'}
              project={'python-api-for-lanner-psp'}
              docs={[
                {name: 'Lanner PSP User Manual', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/Lanner+PSP+User+Manual+v0.0.12.pdf'}
              ]}
              photos={[]}
            />
          }
        />
        <Route
          exact path="/connectivity/connectivity-manager"
          render={() =>
            <Project 
              title={'Connectivity Manager'}
              color={'linear-gradient(135deg, #e9f7f8 25%, #14a38e)'}
              docs={[
                {name: 'User Manual', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/cm/documents/LINK+Connectivity+Manager+User+Manual+v0.3.pdf'},
                {name: 'Release Note for API', link: 'https://link.lannerinc.com/cm/cmapi-release-note.html'},
                {name: 'Release Note for GUI', link: 'https://hub.docker.com/r/lanneriotsw/cms-frontend'}
              ]}
              photos={[]}
            />
          }
        />
        <Route
          exact path={`/${firstTierRouter}/${secondTierRouter}`}
          render={() =>
            <Project 
              title={'No Data'}
              docs={[]}
              photos={[]}
              // color={'linear-gradient(135deg, #1489a3 25%, #f20c0c)'}
              // docs={[
              //   {name: 'SNMP MiBs Trap', link: 'https://lannerinc.s3.ap-northeast-1.amazonaws.com/link/SNMP_MiBs_Trap_20220609.pdf'}
              // ]}
            />
          }
        />
        <Route
          path='*'
          render={() =>
            <PageNotFound />
          }
        />
      </Switch>
    </Router>
  );
}

export default App;
