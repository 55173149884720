import { Link } from 'react-router-dom';
import './style.scss';

const Logo = () => {
    return (
        <div className="link__svg__logo">
            <Link to={'/'} >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 979 257.444" >
                    <path d="M557.294,208.792c0-1.386,0-2.509,0-3.631c0-53.339-0.004-106.678,0.007-160.017
                        c0.001-4.318-0.515-3.78,3.712-3.786c14.119-0.02,28.238-0.008,42.357-0.007c0.686,0,1.375,0.035,2.059-0.004
                        c1.106-0.063,1.648,0.397,1.603,1.532c-0.027,0.685,0.002,1.372,0.002,2.059c0,58.241-0.02,116.483,0.029,174.724
                        c0.005,6.37-2.227,11.634-6.739,16.029c-4.816,4.691-10.571,7.782-16.967,9.612c-11.1,3.177-22.404,3.846-33.753,1.703
                        c-12.933-2.442-23.096-9.244-30.636-20.086c-20.985-30.176-42.042-60.302-63.073-90.446c-12.226-17.524-24.457-35.044-36.688-52.565
                        c-0.54-0.773-1.106-1.528-2.209-2.175c0,1.117,0,2.234,0,3.351c0,52.064,0.004,104.128-0.006,156.193
                        c-0.001,4.666,0.496,4.014-4.095,4.023c-13.825,0.026-27.65,0.01-41.475,0.009c-0.686,0-1.375-0.035-2.059,0.004
                        c-1.113,0.064-1.638-0.403-1.568-1.54c0.036-0.586,0.003-1.176,0.003-1.764c0-57.457-0.005-114.914,0.006-172.371
                        c0.001-7.17,1.695-13.801,6.649-19.261c4.021-4.43,9.187-6.95,14.854-8.494c8.025-2.186,16.23-2.649,24.49-2.141
                        c4.887,0.301,9.78,0.685,14.575,1.766c11.266,2.541,19.88,8.885,26.348,18.35c12,17.56,24.037,35.094,36.062,52.636
                        c21.501,31.366,43.004,62.732,64.518,94.09C555.776,207.275,556.103,208.114,557.294,208.792z"
                    />
                    <path d="M8.696,121.075c0-25.494,0-50.988,0-76.482c0-3.59-0.164-3.238,3.104-3.239
                        c14.316-0.006,28.632-0.002,42.947-0.002c0.49,0,0.983,0.035,1.47-0.002c1.163-0.089,1.581,0.479,1.522,1.579
                        c-0.042,0.782-0.006,1.568-0.006,2.353c0,49.027,0.004,98.054-0.011,147.081c-0.001,2.661,0.181,5.288,0.884,7.858
                        c1.315,4.805,4.218,8.318,8.639,10.61c4.418,2.29,9.191,3.314,14.058,3.989c6.422,0.89,12.889,1.074,19.361,1.076
                        c40.692,0.009,81.385,0,122.077,0.011c4.359,0.001,3.723-0.474,3.741,3.737c0.033,7.648,0.016,15.296,0.006,22.945
                        c-0.003,2.698-0.021,2.719-2.726,2.719c-22.748,0.006-45.497,0.003-68.245,0.003c-20.199,0-40.399,0.136-60.597-0.044
                        c-15.817-0.141-31.509-1.584-46.725-6.325c-8.291-2.583-16.101-6.179-23.044-11.453c-10.857-8.247-16.459-19.166-16.457-32.874
                        C8.699,170.102,8.697,145.588,8.696,121.075z"
                    />
                    <path d="M772.486,140.508c47.53,34.96,94.732,69.678,142.019,104.459c-0.624,0.851-1.462,0.518-2.144,0.517
                        c-19.899-0.026-39.798-0.093-59.698-0.096c-1.47,0-2.604-0.438-3.755-1.291c-37.086-27.483-74.194-54.935-111.279-82.419
                        c-4.65-3.446-9.08-7.167-12.986-11.467c-1.852-2.038-3.52-4.204-4.719-6.708c-1.532-3.2-1.3-6.235,0.682-9.179
                        c2.058-3.059,4.787-5.437,7.693-7.62c7.6-5.71,15.199-11.423,22.86-17.051c30.014-22.046,60.064-44.043,90.054-66.121
                        c2.076-1.529,4.102-2.249,6.711-2.235c18.625,0.101,37.251,0.054,55.876,0.054c0.961,0,1.921,0,2.983,0
                        c-0.328,0.468,0.011,0.025,0.008,0.417c-0.001,0.088-0.045,0.21-0.111,0.259C862.052,74.784,817.419,107.536,772.486,140.508z"
                    />
                    <path d="M258.956,143.333c0-32.551,0-65.102,0-97.653c0-0.588-0.006-1.177,0.001-1.765
                        c0.031-2.533,0.032-2.558,2.593-2.56c14.707-0.009,29.414-0.01,44.12,0.002c2.43,0.002,2.432,0.036,2.483,2.407
                        c0.011,0.49,0.002,0.98,0.002,1.471c0,65.396,0.002,130.792-0.004,196.188c0,4.512,0.395,3.871-3.947,3.879
                        c-13.922,0.024-27.845,0.009-41.767,0.009c-0.686,0-1.374-0.029-2.059-0.001c-0.979,0.04-1.455-0.377-1.42-1.382
                        c0.027-0.783-0.002-1.568-0.002-2.353C258.956,208.827,258.956,176.08,258.956,143.333z"
                    />
                    <path d="M666.467,143.235c0-32.749-0.003-65.498,0.004-98.247c0.001-4.182-0.352-3.623,3.587-3.629
                        c13.923-0.019,27.846-0.007,41.77-0.007c0.49,0,0.981-0.01,1.471,0.002c2.132,0.053,2.135,0.055,2.202,2.061
                        c0.016,0.49,0.003,0.98,0.003,1.471c0,65.596,0.003,131.193-0.006,196.789c0,4.125,0.462,3.624-3.568,3.629
                        c-13.923,0.018-27.846,0.007-41.77,0.007c-0.49,0-0.981,0.01-1.471-0.002c-2.169-0.054-2.173-0.056-2.22-2.355
                        c-0.014-0.686-0.002-1.373-0.002-2.059C666.467,208.341,666.467,175.788,666.467,143.235z"
                    />
                    <path d="M966.819,14.22c-0.988,2.515-1.992,5.024-2.96,7.547c-1.682,4.386-3.39,8.764-4.981,13.183
                        c-0.485,1.349-1.247,1.736-2.602,1.727c-1.407-0.009-2.294-0.254-2.852-1.789c-2.172-5.978-4.491-11.903-6.803-17.83
                        c-0.372-0.954-0.508-2.061-1.663-3.106c0,1.257,0,2.182,0,3.108c-0.001,5.978-0.047,11.956,0.028,17.933
                        c0.02,1.622-0.836,1.68-2.025,1.661c-1.138-0.018-2.103,0.053-2.092-1.613c0.056-8.133,0.044-16.267,0.005-24.4
                        c-0.006-1.317,0.555-1.615,1.759-1.674c4.818-0.236,4.812-0.273,6.478,4.134c1.938,5.126,3.863,10.258,5.845,15.367
                        c0.341,0.88,0.407,1.937,1.486,2.722c0.881-2.314,1.742-4.542,2.577-6.78c1.676-4.493,3.388-8.973,4.972-13.498
                        c0.498-1.422,1.202-2,2.746-1.95c5.614,0.181,4.582-0.755,4.633,4.646c0.067,6.957,0.028,13.915-0.003,20.872
                        c-0.003,0.706,0.405,1.713-0.612,2.031c-0.928,0.29-1.976,0.306-2.896-0.033c-0.809-0.298-0.524-1.17-0.527-1.812
                        c-0.02-4.802-0.011-9.603-0.012-14.405c0-1.958,0-3.915,0-5.873C967.155,14.333,966.987,14.277,966.819,14.22z"
                    />
                    <path d="M924.371,24.581c-0.001-3.134,0.01-6.267-0.004-9.401c-0.012-2.639-0.025-2.642-2.778-2.658
                        c-1.469-0.009-2.939-0.037-4.406,0.007c-1.131,0.034-1.586-0.444-1.53-1.569c0.051-1.015-0.034-1.983,1.459-1.976
                        c6.169,0.029,12.339,0.03,18.508-0.003c1.469-0.008,1.452,0.905,1.493,1.947c0.043,1.1-0.342,1.632-1.5,1.6
                        c-1.566-0.043-3.133-0.022-4.7-0.004c-2.425,0.028-2.456,0.036-2.465,2.383c-0.02,5.19-0.006,10.38-0.006,15.57
                        c0,1.273,0.013,2.546-0.003,3.819c-0.028,2.167-1.284,2.992-3.356,2.275c-0.937-0.324-0.693-1.079-0.698-1.708
                        C924.359,31.436,924.372,28.008,924.371,24.581z"
                    />
                </svg>
            </Link>
        </div>
    );
};

export default Logo;