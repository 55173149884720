import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import './style.scss';

const Head = (props) => {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuTrgger = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="link__head__wrap">
            <Logo /> 
            <div className={isMenuOpen ? "link__menu__icon--close" : "link__menu__icon--menu"} onClick={menuTrgger}>
                <div className="menu__bar"></div>
                <div className="menu__bar"></div>
                <div className="menu__bar"></div>
            </div>
            <div className={isMenuOpen ? "link__full__menu--open" : "link__full__menu--hidden"}>
                <Link to={'/manageability'}><span onClick={menuTrgger}>MANAGEABILITY</span></Link>
                <Link to={'/reliability'}><span onClick={menuTrgger}>RELIABILITY</span></Link>
                <Link to={'/security'}><span onClick={menuTrgger}>SECURITY</span></Link>
                <Link to={'/performance'}><span onClick={menuTrgger}>PERFORMANCE</span></Link>
                <Link to={'/development'}><span onClick={menuTrgger}>DEVELOPMENT</span></Link>
                <Link to={'/connectivity'}><span onClick={menuTrgger}>CONNECTIVITY</span></Link>
            </div>
        </div>
    );
};

export default Head;