
import './style.scss'

const Infinity = () => {

//     const infinityIcon = document.querySelector('.infinity__svg');
//     const linkContainer = document.getElementById('link-container')

// console.log(linkContainer)

//     linkContainer.addEventListener('mousemove', (e) => {
//         // pageXY 為當下滑鼠所在的座標，除法只是為了降低數值，避免過度rotate
//         let xAxis = (window.innerWidth / 2 - e.pageX)/25;
//         let yAxis = (window.innerHeight / 2 - e.pageY)/25;
//         infinityIcon.style.transform = `rotateX(${yAxis}deg) rotateY(${xAxis}deg)`
//     });

    return (
        <div className="link__wrap" id="link-container">
            <div className="link__section">
                <div className="link__init__animation">
                    
                    <div className="link__init__circle">
                        <div className="link__init__circle__content"></div>
                    </div>
                    <div className="link__init__circle">
                        <div className="link__init__circle__content"></div>
                    </div>
                    <div className="link__init__center__point"></div>
                </div>
                <div className="link__logo__svg">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 979 257.444" >
                        <path d="M557.294,208.792c0-1.386,0-2.509,0-3.631c0-53.339-0.004-106.678,0.007-160.017
                            c0.001-4.318-0.515-3.78,3.712-3.786c14.119-0.02,28.238-0.008,42.357-0.007c0.686,0,1.375,0.035,2.059-0.004
                            c1.106-0.063,1.648,0.397,1.603,1.532c-0.027,0.685,0.002,1.372,0.002,2.059c0,58.241-0.02,116.483,0.029,174.724
                            c0.005,6.37-2.227,11.634-6.739,16.029c-4.816,4.691-10.571,7.782-16.967,9.612c-11.1,3.177-22.404,3.846-33.753,1.703
                            c-12.933-2.442-23.096-9.244-30.636-20.086c-20.985-30.176-42.042-60.302-63.073-90.446c-12.226-17.524-24.457-35.044-36.688-52.565
                            c-0.54-0.773-1.106-1.528-2.209-2.175c0,1.117,0,2.234,0,3.351c0,52.064,0.004,104.128-0.006,156.193
                            c-0.001,4.666,0.496,4.014-4.095,4.023c-13.825,0.026-27.65,0.01-41.475,0.009c-0.686,0-1.375-0.035-2.059,0.004
                            c-1.113,0.064-1.638-0.403-1.568-1.54c0.036-0.586,0.003-1.176,0.003-1.764c0-57.457-0.005-114.914,0.006-172.371
                            c0.001-7.17,1.695-13.801,6.649-19.261c4.021-4.43,9.187-6.95,14.854-8.494c8.025-2.186,16.23-2.649,24.49-2.141
                            c4.887,0.301,9.78,0.685,14.575,1.766c11.266,2.541,19.88,8.885,26.348,18.35c12,17.56,24.037,35.094,36.062,52.636
                            c21.501,31.366,43.004,62.732,64.518,94.09C555.776,207.275,556.103,208.114,557.294,208.792z"
                        />
                        <path d="M8.696,121.075c0-25.494,0-50.988,0-76.482c0-3.59-0.164-3.238,3.104-3.239
                            c14.316-0.006,28.632-0.002,42.947-0.002c0.49,0,0.983,0.035,1.47-0.002c1.163-0.089,1.581,0.479,1.522,1.579
                            c-0.042,0.782-0.006,1.568-0.006,2.353c0,49.027,0.004,98.054-0.011,147.081c-0.001,2.661,0.181,5.288,0.884,7.858
                            c1.315,4.805,4.218,8.318,8.639,10.61c4.418,2.29,9.191,3.314,14.058,3.989c6.422,0.89,12.889,1.074,19.361,1.076
                            c40.692,0.009,81.385,0,122.077,0.011c4.359,0.001,3.723-0.474,3.741,3.737c0.033,7.648,0.016,15.296,0.006,22.945
                            c-0.003,2.698-0.021,2.719-2.726,2.719c-22.748,0.006-45.497,0.003-68.245,0.003c-20.199,0-40.399,0.136-60.597-0.044
                            c-15.817-0.141-31.509-1.584-46.725-6.325c-8.291-2.583-16.101-6.179-23.044-11.453c-10.857-8.247-16.459-19.166-16.457-32.874
                            C8.699,170.102,8.697,145.588,8.696,121.075z"
                        />
                        <path d="M772.486,140.508c47.53,34.96,94.732,69.678,142.019,104.459c-0.624,0.851-1.462,0.518-2.144,0.517
                            c-19.899-0.026-39.798-0.093-59.698-0.096c-1.47,0-2.604-0.438-3.755-1.291c-37.086-27.483-74.194-54.935-111.279-82.419
                            c-4.65-3.446-9.08-7.167-12.986-11.467c-1.852-2.038-3.52-4.204-4.719-6.708c-1.532-3.2-1.3-6.235,0.682-9.179
                            c2.058-3.059,4.787-5.437,7.693-7.62c7.6-5.71,15.199-11.423,22.86-17.051c30.014-22.046,60.064-44.043,90.054-66.121
                            c2.076-1.529,4.102-2.249,6.711-2.235c18.625,0.101,37.251,0.054,55.876,0.054c0.961,0,1.921,0,2.983,0
                            c-0.328,0.468,0.011,0.025,0.008,0.417c-0.001,0.088-0.045,0.21-0.111,0.259C862.052,74.784,817.419,107.536,772.486,140.508z"
                        />
                        <path d="M258.956,143.333c0-32.551,0-65.102,0-97.653c0-0.588-0.006-1.177,0.001-1.765
                            c0.031-2.533,0.032-2.558,2.593-2.56c14.707-0.009,29.414-0.01,44.12,0.002c2.43,0.002,2.432,0.036,2.483,2.407
                            c0.011,0.49,0.002,0.98,0.002,1.471c0,65.396,0.002,130.792-0.004,196.188c0,4.512,0.395,3.871-3.947,3.879
                            c-13.922,0.024-27.845,0.009-41.767,0.009c-0.686,0-1.374-0.029-2.059-0.001c-0.979,0.04-1.455-0.377-1.42-1.382
                            c0.027-0.783-0.002-1.568-0.002-2.353C258.956,208.827,258.956,176.08,258.956,143.333z"
                        />
                        <path d="M666.467,143.235c0-32.749-0.003-65.498,0.004-98.247c0.001-4.182-0.352-3.623,3.587-3.629
                            c13.923-0.019,27.846-0.007,41.77-0.007c0.49,0,0.981-0.01,1.471,0.002c2.132,0.053,2.135,0.055,2.202,2.061
                            c0.016,0.49,0.003,0.98,0.003,1.471c0,65.596,0.003,131.193-0.006,196.789c0,4.125,0.462,3.624-3.568,3.629
                            c-13.923,0.018-27.846,0.007-41.77,0.007c-0.49,0-0.981,0.01-1.471-0.002c-2.169-0.054-2.173-0.056-2.22-2.355
                            c-0.014-0.686-0.002-1.373-0.002-2.059C666.467,208.341,666.467,175.788,666.467,143.235z"
                        />
                        <path d="M966.819,14.22c-0.988,2.515-1.992,5.024-2.96,7.547c-1.682,4.386-3.39,8.764-4.981,13.183
                            c-0.485,1.349-1.247,1.736-2.602,1.727c-1.407-0.009-2.294-0.254-2.852-1.789c-2.172-5.978-4.491-11.903-6.803-17.83
                            c-0.372-0.954-0.508-2.061-1.663-3.106c0,1.257,0,2.182,0,3.108c-0.001,5.978-0.047,11.956,0.028,17.933
                            c0.02,1.622-0.836,1.68-2.025,1.661c-1.138-0.018-2.103,0.053-2.092-1.613c0.056-8.133,0.044-16.267,0.005-24.4
                            c-0.006-1.317,0.555-1.615,1.759-1.674c4.818-0.236,4.812-0.273,6.478,4.134c1.938,5.126,3.863,10.258,5.845,15.367
                            c0.341,0.88,0.407,1.937,1.486,2.722c0.881-2.314,1.742-4.542,2.577-6.78c1.676-4.493,3.388-8.973,4.972-13.498
                            c0.498-1.422,1.202-2,2.746-1.95c5.614,0.181,4.582-0.755,4.633,4.646c0.067,6.957,0.028,13.915-0.003,20.872
                            c-0.003,0.706,0.405,1.713-0.612,2.031c-0.928,0.29-1.976,0.306-2.896-0.033c-0.809-0.298-0.524-1.17-0.527-1.812
                            c-0.02-4.802-0.011-9.603-0.012-14.405c0-1.958,0-3.915,0-5.873C967.155,14.333,966.987,14.277,966.819,14.22z"
                        />
                        <path d="M924.371,24.581c-0.001-3.134,0.01-6.267-0.004-9.401c-0.012-2.639-0.025-2.642-2.778-2.658
                            c-1.469-0.009-2.939-0.037-4.406,0.007c-1.131,0.034-1.586-0.444-1.53-1.569c0.051-1.015-0.034-1.983,1.459-1.976
                            c6.169,0.029,12.339,0.03,18.508-0.003c1.469-0.008,1.452,0.905,1.493,1.947c0.043,1.1-0.342,1.632-1.5,1.6
                            c-1.566-0.043-3.133-0.022-4.7-0.004c-2.425,0.028-2.456,0.036-2.465,2.383c-0.02,5.19-0.006,10.38-0.006,15.57
                            c0,1.273,0.013,2.546-0.003,3.819c-0.028,2.167-1.284,2.992-3.356,2.275c-0.937-0.324-0.693-1.079-0.698-1.708
                            C924.359,31.436,924.372,28.008,924.371,24.581z"
                        />
                    </svg>
                </div>
                <div className="link__logo__desc__svg">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 972.222 81.444">
                    <path d="M582.405,29.544c0,4.868,0,9.219,0,13.57c0,4.49,0.015,8.981-0.005,13.471c-0.015,3.417-0.034,3.417-3.453,3.453
                        C573,60.1,573,60.1,573,53.985c0-11.886-0.009-23.773,0.006-35.659c0.004-3.179,0.039-3.188,3.234-3.213
                        c2.113-0.017,4.247,0.183,6.334-0.053c2.312-0.262,3.218,0.807,4.019,2.777c3.62,8.904,7.389,17.747,11.185,26.803
                        c1.11-0.792,0.703-1.765,0.706-2.546c0.032-8.056,0.001-16.112,0.03-24.169c0.01-2.746,0.066-2.746,2.861-2.815
                        c6.505-0.161,6.505-0.161,6.505,6.326c0,11.886,0.013,23.773-0.009,35.659c-0.005,2.859-0.057,2.911-2.75,2.928
                        c-3.166,0.019-6.855,0.931-9.353-0.38c-2.561-1.344-2.774-5.44-3.971-8.344c-2.611-6.336-5.074-12.732-7.633-19.09
                        C583.843,31.413,583.756,30.448,582.405,29.544z"/>
                    <path d="M895.66,32.47c2.896-4.818,5.953-9.548,8.616-14.491c1.272-2.361,2.792-3.135,5.338-2.916
                        c2.488,0.214,5.009,0.047,7.478,0.047c0.626,1.367-0.439,2.065-0.969,2.879c-3.239,4.979-6.406,10.013-9.866,14.836
                        c-1.69,2.356-1.864,4.116-0.163,6.604c3.721,5.443,7.097,11.124,10.578,16.73c0.677,1.09,1.65,2.047,1.852,3.866
                        c-3.7,0-7.324-0.027-10.947,0.013c-1.577,0.017-1.85-1.262-2.402-2.248c-3.156-5.639-6.287-11.293-9.44-16.967
                        c-1.326,0.505-0.737,1.578-0.747,2.348c-0.058,4.755-0.127,9.514,0.014,14.266c0.064,2.144-0.737,2.786-2.745,2.629
                        c-1.707-0.133-3.433,0.002-5.15-0.034c-2.7-0.057-2.757-0.075-2.769-2.946c-0.032-7.926-0.011-15.853-0.011-23.779
                        c0-5.152,0.066-10.305-0.033-15.456c-0.038-1.969,0.403-3.055,2.632-2.755c2.572,0.347,5.805-1.145,7.584,0.69
                        c1.437,1.481,0.385,4.538,0.433,6.894c0.064,3.165,0.016,6.333,0.016,9.5C895.194,32.276,895.427,32.373,895.66,32.47z"/>
                    <path d="M726.028,30.984c0.147-1.562,0.243-2.585,0.336-3.568c0.32-0.144,0.555-0.338,0.795-0.344
                        c5.671-0.146,4.738-0.436,4.765,4.387c0.048,8.449,0.024,16.899,0.006,25.349c-0.005,2.373,0.044,4.765-0.585,7.082
                        c-1.446,5.327-4.849,8.599-10.277,9.798c-5.304,1.171-10.478,0.602-15.63-0.875c-1.647-0.472-2.535-1.243-2.031-3.086
                        c0.273-1,0.409-2.037,0.627-3.16c2.845,0.797,5.292,2.047,8.044,2.49c9.245,1.487,14.522-3.322,13.587-13.12
                        c-1.315,0.086-1.959,1.242-2.927,1.853c-8.158,5.159-18.275,1.526-21.084-7.655c-1.477-4.827-1.194-9.605,0.542-14.355
                        C706.365,24.369,720.169,23.66,726.028,30.984z"/>
                    <path d="M378.541,55.913c-1.426,0.143-1.984,1.171-2.84,1.733c-8.191,5.378-18.535,1.706-21.355-7.628
                        c-1.212-4.013-1.117-8.03-0.129-12.107c2.96-12.211,16.731-15.388,24.478-7.207c0.607-1.459-0.559-3.958,2.505-3.782
                        c3.409,0.196,3.454-0.076,3.461,3.471c0.017,9.11-0.076,18.221,0.025,27.329c0.129,11.578-5.944,16.588-16.902,16.599
                        c-3.068,0.003-6.034-0.52-8.956-1.384c-3.017-0.892-3.018-0.902-2.517-4.107c0.101-0.643,0.238-1.28,0.441-2.364
                        c1.168,0.463,2.196,0.87,3.224,1.277c3.119,1.235,6.358,1.765,9.693,1.449c4.55-0.432,7.536-3.113,8.45-7.564
                        C378.489,59.825,378.828,57.995,378.541,55.913z"/>
                    <path d="M539.374,56.292c-2.2,1.754-4.362,3.377-7.113,4.055c-6.948,1.711-13.1-1.053-15.93-7.606
                        c-2.441-5.652-2.723-11.528-0.652-17.357c3.308-9.313,14.268-12.257,22.012-6.017c0.466,0.375,0.727,1.152,1.905,0.796
                        c0-4.988-0.04-10.077,0.032-15.165c0.015-1.091-0.697-2.754,0.691-3.167c1.442-0.429,3.122-0.1,4.693-0.006
                        c0.207,0.012,0.495,0.576,0.545,0.914c0.096,0.646,0.059,1.314,0.059,1.973c0.002,13.977,0.008,27.955-0.003,41.932
                        c-0.003,3.094-2.143,4.513-5.028,3.306C539.012,59.294,540.238,57.586,539.374,56.292z"/>
                    <path d="M10.997,37.443c0-6.604,0.047-13.208-0.031-19.811c-0.021-1.799,0.38-2.715,2.406-2.556
                        c1.969,0.154,3.965,0.127,5.939,0.006c1.771-0.108,2.271,0.646,2.25,2.305c-0.067,5.15-0.023,10.302-0.023,15.453
                        c0,5.019-0.006,10.038,0.002,15.057c0.006,3.923,0.013,3.931,3.765,3.948c3.038,0.013,6.077,0.052,9.113-0.014
                        c1.522-0.033,1.978,0.532,2.054,2.072c0.304,6.137,0.367,6.134-5.928,6.134c-5.415,0-10.83,0.015-16.245-0.006
                        c-3.251-0.012-3.284-0.036-3.295-3.173C10.981,50.387,10.997,43.915,10.997,37.443z"/>
                    <path d="M62.21,39.688c0.004-6.106-2.593-9.025-9.668-8.328c-2.687,0.265-4.962,1.541-7.311,2.694
                        c-1.47-4.53-1.322-4.876,2.751-6.451c3.653-1.412,7.393-1.825,11.257-1.011c5.409,1.14,8.174,4.101,8.905,9.642
                        c0.503,3.811,0.306,7.644,0.333,11.47c0.014,1.98,0.002,3.96,0.002,5.941c0,1.963,0,3.926,0,5.868
                        c-4.944,1.033-4.944,1.033-6.103-2.712c-4.173,3.76-8.942,4.896-14.328,3.463c-3.758-1-6.285-3.116-7.105-7.082
                        c-0.857-4.142,0.137-7.594,3.559-10.18c3.185-2.407,6.935-3.118,10.803-3.296C57.53,39.604,59.761,39.688,62.21,39.688z"/>
                    <path d="M767.78,59.723c-5.06,0.583-5.06,0.583-5.963-2.658c-1.191-0.032-1.708,1.061-2.551,1.588
                        c-4.679,2.923-9.56,2.929-14.36,0.678c-5.577-2.614-6.755-10.707-2.375-15.175c3.38-3.448,7.782-4.195,12.335-4.469
                        c2.21-0.133,4.456,0.299,6.626-0.302c0.02-6.262-3.08-8.624-9.513-8.035c-2.696,0.247-5.044,1.376-7.321,2.731
                        c-1.717-4.067-1.469-4.851,2.122-6.302c3.749-1.514,7.614-2.041,11.626-1.217c5.458,1.121,8.266,4.052,9.022,9.536
                        c0.434,3.149,0.359,6.321,0.354,9.489C767.775,50.309,767.78,55.031,767.78,59.723z"/>
                    <path d="M636.138,39.687c-0.32-6.647-2.819-8.911-8.91-8.399c-2.905,0.244-5.571,1.248-8.027,2.981
                        c-1.291-4.975-1.218-5.131,2.931-6.71c3.53-1.343,7.145-1.759,10.875-1.024c5.644,1.112,8.46,4.103,9.177,9.828
                        c0.56,4.471,0.258,8.966,0.322,13.451c0.039,2.771,0.043,5.543,0.075,8.315c0.009,0.775,0.194,1.551-0.888,1.869
                        c-2.675,0.788-4.369-0.122-5.069-2.863c-1.103-0.283-1.507,0.768-2.188,1.231c-4.562,3.099-9.429,3.254-14.326,1.185
                        c-6.035-2.55-7.404-10.941-2.66-15.581c3.333-3.26,7.601-4.042,12.016-4.262C631.681,39.596,633.907,39.687,636.138,39.687z"/>
                    <path d="M324.21,44.974c0.086,9.879,7.656,12.836,20.175,8.205c0.122,0.201,0.341,0.399,0.349,0.605
                        c0.204,5.316,0.144,5.36-4.971,6.459c-3.4,0.731-6.776,0.826-10.193,0.078c-5.638-1.234-9.427-4.481-11.121-10.037
                        c-1.504-4.931-1.642-9.895,0.262-14.733c3.194-8.117,12.897-11.789,20.769-7.972c3.002,1.456,4.969,3.843,6.132,6.925
                        c0.985,2.611,1.367,5.331,1.606,8.106c0.177,2.057-0.701,2.423-2.499,2.394c-5.538-0.087-11.079-0.032-16.619-0.031
                        C326.802,44.974,325.503,44.974,324.21,44.974z"/>
                    <path d="M161.826,45.32c1.34,10.127,8.387,12.778,20.268,7.838c0.157,0.183,0.441,0.359,0.452,0.551
                        c0.314,5.351,0.234,5.417-4.869,6.53c-2.869,0.626-5.752,0.839-8.649,0.399c-7.517-1.141-11.773-5.147-13.309-12.615
                        c-0.724-3.522-0.702-7.05,0.235-10.58c2.575-9.703,12.262-13.287,20.672-10.179c3.564,1.317,5.711,4.135,7.038,7.608
                        c0.955,2.498,1.023,5.162,1.425,7.765c0.267,1.728-0.315,2.382-2.088,2.361c-5.676-0.067-11.354-0.041-17.031-0.012
                        C164.664,44.993,163.332,44.752,161.826,45.32z"/>
                    <path d="M817.764,26.209c9.759,0.016,16.663,7.109,16.721,17.18c0.058,9.989-7.316,17.556-16.998,17.441
                        c-9.43-0.112-16.681-7.785-16.559-17.522C801.054,33.261,808.013,26.193,817.764,26.209z"/>
                    <path d="M485.339,45.297c1.13,10.096,8.613,12.942,20.158,7.824c0.472,0.111,0.587,0.501,0.607,0.887
                        c0.254,4.948,0.086,5.128-4.856,6.216c-2.866,0.631-5.75,0.856-8.649,0.428c-7.541-1.112-11.806-5.091-13.374-12.545
                        c-0.741-3.521-0.719-7.044,0.193-10.579c2.45-9.504,11.912-13.42,20.613-10.299c3.258,1.169,5.458,3.625,6.744,6.846
                        c0.39,0.976,0.739,1.978,0.996,2.996c1.969,7.786,1.874,7.898-5.951,7.904c-5.148,0.004-10.297,0.02-15.445,0.044
                        C486.124,45.02,485.873,45.149,485.339,45.297z"/>
                    <path d="M440.158,59.912c-0.146-1.066-0.281-2.051-0.419-3.063c-1.284,0.142-1.815,1.196-2.683,1.745
                        c-4.791,3.03-9.804,3.031-14.689,0.619c-5.49-2.711-6.427-10.848-1.961-15.228c3.323-3.259,7.58-4.053,11.989-4.277
                        c2.208-0.112,4.427-0.02,6.631-0.02c0.005-6.226-2.346-8.571-8.043-8.457c-3.236,0.065-6.095,1.323-8.888,2.931
                        c-0.995-5.165-0.988-5.185,3.46-6.773c3.824-1.366,7.695-1.55,11.658-0.608c3.823,0.908,6.232,3.248,7.39,6.931
                        c0.636,2.023,0.884,4.11,0.878,6.236c-0.018,5.799,0.009,11.599-0.003,17.398c-0.006,2.669-0.029,2.679-2.857,2.685
                        C441.838,60.033,441.055,59.958,440.158,59.912z"/>
                    <path d="M246.081,37.987c0,6.456-0.052,12.913,0.032,19.368c0.026,1.97-0.482,2.925-2.651,2.721
                        c-1.828-0.172-3.699-0.175-5.527,0c-2.217,0.212-2.603-0.822-2.594-2.757c0.061-13.175,0.065-26.351-0.003-39.527
                        c-0.01-1.986,0.474-2.916,2.633-2.719c1.829,0.167,3.7,0.175,5.527-0.002c2.218-0.214,2.633,0.836,2.612,2.757
                        C246.036,24.547,246.081,31.267,246.081,37.987z"/>
                    <path d="M261.671,31.087c3.98-4.279,8.431-5.516,13.605-4.582c4.379,0.791,6.997,3.484,8.256,7.567
                        c0.579,1.877,0.916,3.84,0.911,5.842c-0.012,5.801-0.049,11.604,0.052,17.403c0.034,1.946-0.404,2.832-2.598,2.775
                        c-3.612-0.095-3.623,0.05-3.642-3.67c-0.024-4.878-0.007-9.757-0.072-14.634c-0.019-1.441-0.148-2.899-0.418-4.313
                        c-0.543-2.841-1.594-5.334-4.889-5.973c-3.39-0.658-6.299,0.191-8.523,2.858c-2.027,2.431-2.72,5.4-2.794,8.475
                        c-0.112,4.612-0.029,9.229-0.116,13.842c-0.021,1.095,0.66,2.742-0.745,3.153c-1.452,0.424-3.166,0.311-4.687,0.021
                        c-1.121-0.214-0.732-1.528-0.735-2.392c-0.024-7.12-0.014-14.24-0.014-21.36c0-2.241,0.115-4.491-0.034-6.722
                        c-0.133-1.997,0.709-2.536,2.542-2.395C261.753,27.289,260.718,26.161,261.671,31.087z"/>
                    <path d="M124.492,31.103c3.995-4.294,8.445-5.532,13.62-4.598c4.379,0.791,6.997,3.485,8.257,7.567
                        c0.579,1.876,0.915,3.841,0.911,5.842c-0.012,5.801-0.05,11.604,0.052,17.403c0.034,1.946-0.404,2.832-2.598,2.775
                        c-3.612-0.095-3.623,0.05-3.642-3.67c-0.025-4.878-0.007-9.757-0.072-14.634c-0.019-1.441-0.148-2.899-0.418-4.313
                        c-0.543-2.841-1.594-5.334-4.889-5.973c-3.39-0.658-6.299,0.191-8.523,2.859c-2.027,2.431-2.72,5.4-2.794,8.475
                        c-0.111,4.612-0.03,9.229-0.117,13.842c-0.021,1.095,0.66,2.742-0.745,3.153c-1.452,0.424-3.166,0.311-4.687,0.021
                        c-1.121-0.214-0.732-1.528-0.735-2.392c-0.024-7.12-0.014-14.24-0.014-21.36c0-2.241,0.116-4.491-0.034-6.722
                        c-0.134-1.997,0.714-2.445,2.541-2.416C123.861,27.011,123.863,26.908,124.492,31.103z"/>
                    <path d="M84.864,31.087c3.98-4.278,8.431-5.516,13.605-4.582c4.379,0.791,6.997,3.484,8.256,7.567
                        c0.579,1.877,0.916,3.841,0.912,5.842c-0.012,5.801-0.049,11.604,0.052,17.403c0.034,1.947-0.404,2.832-2.598,2.774
                        c-3.612-0.095-3.623,0.05-3.642-3.67c-0.024-4.878-0.007-9.757-0.072-14.634c-0.019-1.441-0.148-2.899-0.418-4.313
                        c-0.543-2.841-1.594-5.334-4.889-5.973c-3.39-0.658-6.3,0.191-8.523,2.859c-2.027,2.431-2.72,5.4-2.794,8.475
                        c-0.111,4.612-0.029,9.229-0.116,13.842c-0.021,1.095,0.66,2.742-0.745,3.153c-1.452,0.424-3.166,0.311-4.687,0.021
                        c-1.121-0.214-0.732-1.528-0.735-2.392c-0.024-7.12-0.014-14.24-0.014-21.36c0-2.241,0.116-4.491-0.034-6.722
                        c-0.134-1.997,0.71-2.535,2.542-2.395C84.955,27.288,83.911,26.169,84.864,31.087z"/>
                    <path d="M648.462,27.304c6.232-0.714,6.216-0.709,8.059,4.882c2.017,6.12,4.072,12.227,6.099,18.344
                        c0.286,0.863,0.334,1.852,1.44,2.293c2.477-7.343,4.956-14.691,7.434-22.04c1.313-3.893,1.312-3.899,5.479-3.773
                        c0.498,0.015,0.994,0.125,1.428,0.182c0.238,0.819-0.013,1.421-0.236,2.047c-3.365,9.412-6.752,18.816-10.041,28.254
                        c-0.651,1.867-1.575,2.882-3.656,2.564c-0.773-0.118-1.596-0.123-2.366,0.001c-1.836,0.298-2.529-0.693-3.07-2.245
                        c-2.552-7.328-5.222-14.614-7.771-21.943C650.278,33.045,648.942,30.318,648.462,27.304z"/>
                    <path d="M296.894,32.26c-1.794-0.469-3.639-0.157-5.463-0.234c-1.934-0.082-1.442-1.505-1.465-2.591
                        c-0.024-1.132-0.329-2.435,1.602-2.465c1.802-0.028,4.082,0.82,5.29-0.459c1.182-1.251,0.341-3.507,0.404-5.318
                        c0.041-1.185,0.292-2.02,1.619-2.286c1.399-0.28,2.671-1.125,4.546-0.938c0,2.169,0.099,4.367-0.031,6.55
                        c-0.106,1.789,0.287,2.766,2.338,2.507c1.167-0.148,2.369-0.047,3.554-0.022c2.647,0.055,3.608,1.449,2.836,3.993
                        c-0.238,0.786-0.789,0.989-1.465,1.002c-1.712,0.034-3.433,0.128-5.136,0.003c-1.872-0.137-2.141,0.814-2.12,2.371
                        c0.069,5.138-0.05,10.279,0.073,15.415c0.116,4.825,2.406,6.538,7.2,5.674c0.472-0.085,0.923-0.523,1.418-0.18
                        c0.116,0.125,0.272,0.214,0.283,0.317c0.454,4.586,0.09,5.016-4.402,5.229c-6.972,0.331-10.645-3.101-10.688-10.039
                        c-0.031-5.007,0.015-10.014-0.023-15.02C297.257,34.612,297.577,33.398,296.894,32.26z"/>
                    <path d="M464.376,17.941c0,2.472,0.107,4.712-0.034,6.937c-0.116,1.825,0.684,2.266,2.302,2.142
                        c1.31-0.1,2.641,0.068,3.95-0.041c1.818-0.151,2.646,0.399,2.543,2.401c-0.131,2.529-0.009,2.556-2.677,2.642
                        c-1.185,0.038-2.385,0.121-3.554-0.016c-2.182-0.256-2.617,0.773-2.568,2.729c0.118,4.74-0.012,9.486,0.053,14.229
                        c0.08,5.849,2.194,7.483,7.964,6.301c0.222-0.045,0.461-0.006,0.686-0.006c1.047,4.8,0.806,5.166-4.081,5.528
                        c-6.964,0.515-10.678-3.042-10.721-9.959c-0.034-5.402-0.114-10.807,0.045-16.205c0.064-2.169-0.719-2.833-2.706-2.604
                        c-0.65,0.075-1.317,0.027-1.975,0.008c-2.723-0.079-2.761-0.202-2.734-2.949c0.015-1.461,0.392-2.146,2.008-2.115
                        c1.67,0.032,3.772,0.74,4.918-0.416c1.143-1.152,0.463-3.255,0.389-4.918c-0.082-1.834,0.659-2.597,2.368-2.944
                        C461.69,18.453,462.754,17.85,464.376,17.941z"/>
                    <path d="M786.539,17.677c0,2.327-0.011,4.161,0.003,5.995c0.025,3.3,0.031,3.301,3.507,3.329
                        c0.791,0.006,1.584,0.033,2.373-0.005c2.965-0.14,2.783,0.013,2.855,3.203c0.031,1.38-0.546,1.887-1.872,1.837
                        c-1.448-0.054-2.906,0.072-4.35-0.028c-1.819-0.126-2.599,0.466-2.552,2.435c0.116,4.876-0.057,9.76,0.065,14.636
                        c0.142,5.701,2.448,7.364,8.192,6.159c0.234-0.049,0.481-0.036,0.718-0.051c0.113,0.241,0.308,0.47,0.316,0.705
                        c0.149,4.41-0.101,4.689-4.422,4.934c-2.276,0.129-4.47-0.217-6.517-1.261c-3.051-1.557-4.207-4.21-4.219-7.477
                        c-0.02-5.538,0.001-11.077-0.019-16.615c-0.013-3.393-0.033-3.394-3.487-3.444c-0.527-0.008-1.06-0.051-1.581,0.007
                        c-1.83,0.203-2.639-0.432-2.523-2.423c0.145-2.503,0.032-2.523,2.705-2.61c4.799-0.156,4.955-0.16,4.826-4.739
                        c-0.065-2.29,0.67-3.432,2.866-3.79C784.32,18.326,785.194,18.026,786.539,17.677z"/>
                    <path d="M960.422,55.089c0.643,5.217,0.31,5.618-4.611,5.741c-6.823,0.171-10.217-3.11-10.268-10.01
                        c-0.04-5.274,0.016-10.55-0.018-15.824c-0.019-2.872-0.063-2.876-2.771-2.966c-0.659-0.022-1.319,0.012-1.978-0.007
                        c-2.584-0.075-3.549-1.559-2.738-4.069c0.336-1.04,1.141-0.855,1.894-0.957c1.778-0.241,4.004,0.849,5.246-0.617
                        c1.046-1.234,0.357-3.302,0.321-4.99c-0.035-1.633,0.624-2.381,2.174-2.669c1.148-0.213,2.255-0.652,3.786-1.112
                        c0,2.764,0.029,5.244-0.013,7.724c-0.02,1.164,0.458,1.681,1.62,1.666c1.846-0.024,3.693,0.03,5.538-0.003
                        c1.945-0.035,1.549,1.384,1.555,2.491c0.005,1.111,0.393,2.531-1.523,2.53c-1.846-0.001-3.692,0.023-5.538,0.023
                        c-1.138,0-1.655,0.535-1.646,1.69c0.043,5.537-0.135,11.085,0.145,16.61c0.221,4.351,2.64,5.931,7.004,5.165
                        C959.208,55.398,959.804,55.232,960.422,55.089z"/>
                    <path d="M413.025,31.663c-7.752-0.144-11.337,2.987-11.847,10.648c-0.323,4.865-0.106,9.764-0.181,14.648
                        c-0.015,1.001,0.507,2.492-0.646,2.862c-1.587,0.509-3.417,0.538-5.028-0.086c-0.795-0.308-0.512-1.403-0.513-2.169
                        c-0.011-9.373,0.036-18.747-0.043-28.119c-0.016-1.896,0.566-2.532,2.482-2.491c3.018,0.066,3.009-0.056,3.287,2.396
                        c0.052,0.456-0.286,1.039,0.368,1.362c1.087-0.093,1.295-1.196,1.974-1.759c1.231-1.02,2.493-1.915,4.058-2.376
                        C411.963,25.095,413.365,26.178,413.025,31.663z"/>
                    <path d="M199.173,31.11c2.614-3.175,5.321-5.089,9.097-4.906c3.043,0.147,3.26,0.345,3.242,3.521
                        c-0.004,0.641,0.147,1.326-0.35,1.881c-0.109,0.049-0.219,0.144-0.326,0.142c-7.262-0.172-10.254,2.384-11.306,9.625
                        c-0.746,5.131-0.217,10.29-0.407,15.433c-0.037,1.005,0.511,2.363-0.557,2.93c-1.416,0.751-3.111,0.387-4.645,0.191
                        c-0.928-0.118-0.798-1.224-0.8-2.003c-0.009-4.36-0.006-8.719-0.006-13.078c0-4.888-0.028-9.776,0.017-14.664
                        c0.013-1.442-0.632-3.25,2.086-3.229c3.321,0.026,3.32-0.139,3.641,2.88C198.885,30.079,198.976,30.321,199.173,31.11z"/>
                    <path d="M848.597,30.998c2.215-2.614,4.487-4.532,7.736-4.785c3.975-0.309,4.631,0.276,4.523,4.207
                        c-0.01,0.375-0.135,0.746-0.222,1.199c-0.418,0.048-0.802,0.189-1.14,0.117c-5.313-1.145-9.231,2.441-10.431,8.364
                        c-0.926,4.571-0.501,9.217-0.573,13.834c-0.017,1.057,0.016,2.114-0.006,3.17c-0.062,2.96-0.069,2.96-3.146,2.933
                        c-2.793-0.024-2.854-0.024-2.863-2.813c-0.03-9.115-0.024-18.231,0.007-27.346c0.003-0.98-0.493-2.423,0.819-2.75
                        c1.361-0.339,2.976-0.52,4.274,0.13C848.856,27.9,847.83,29.467,848.597,30.998z"/>
                    <path d="M925.219,43.134c0-4.486,0.081-8.973-0.037-13.456c-0.053-2.007,0.51-2.787,2.644-2.734
                        c3.562,0.089,3.6-0.046,3.607,3.307c0.019,9.103,0.015,18.206-0.009,27.309c-0.002,0.853,0.376,2.127-0.827,2.328
                        c-1.642,0.275-3.429,0.593-4.978-0.282c-0.728-0.411-0.387-1.459-0.39-2.224C925.208,52.633,925.219,47.883,925.219,43.134z"/>
                    <path d="M685.776,43.339c0-4.486-0.027-8.971,0.009-13.456c0.023-2.901,0.064-2.901,3.183-2.888
                        c2.941,0.012,3.008,0.011,3.017,2.656c0.031,9.235,0.019,18.47,0.001,27.705c-0.002,0.882,0.374,2.198-0.64,2.48
                        c-1.62,0.451-3.422,0.517-5.037-0.085c-0.8-0.298-0.522-1.396-0.525-2.162C685.769,52.838,685.777,48.089,685.776,43.339z"/>
                    <path d="M931.735,16.565c-0.028,3.682,0.332,3.29-3.407,3.29c-3.731,0.001-3.398,0.385-3.399-3.295
                        c-0.001-3.687-0.321-3.289,3.403-3.291C932.076,13.268,931.705,12.891,931.735,16.565z"/>
                    <path d="M688.883,13.262c3.722,0.037,3.404-0.391,3.403,3.298c-0.001,3.679,0.333,3.296-3.399,3.295
                        c-3.736-0.001-3.402,0.394-3.4-3.291C685.489,12.895,685.135,13.295,688.883,13.262z"/>
                    <path d="M725.854,43.696c-0.1,2.913-0.758,6.092-3.226,8.619c-3.96,4.054-10.156,3.452-13.261-1.274
                        c-3.091-4.706-2.827-11.945,0.601-16.417c3.387-4.419,9.355-4.533,12.948-0.256C725.074,36.937,725.551,40.05,725.854,43.696z"/>
                    <path d="M378.582,43.722c-0.099,2.887-0.758,6.066-3.225,8.593c-3.96,4.055-10.156,3.452-13.261-1.274
                        c-3.092-4.706-2.827-11.945,0.601-16.417c3.387-4.42,9.36-4.537,12.947-0.254C377.399,36.466,378.399,39.503,378.582,43.722z"/>
                    <path d="M520.843,41.95c-0.019-1.306,0.433-3.762,1.604-6.031c2.884-5.591,10.173-6.324,14.164-1.453
                        c3.783,4.616,3.781,13.506-0.003,18.155c-3.596,4.418-9.92,4.238-13.19-0.439C521.544,49.502,520.861,46.432,520.843,41.95z"/>
                    <path d="M62.391,46.999c-0.261,6.043-4.659,9.69-10.324,8.635c-2.722-0.507-4.515-2.217-4.772-4.553
                        c-0.263-2.391,0.722-4.042,3.259-5.308c3.322-1.657,6.895-1.567,10.462-1.38C62.924,44.493,62.252,46.015,62.391,46.999z"/>
                    <path d="M757.121,44.316c5.051-0.048,5.359,0.34,4.232,4.966c-1.228,5.039-6.118,7.776-11.007,6.111
                        c-1.997-0.68-3.419-1.959-3.731-4.176c-0.321-2.274,0.525-3.947,2.763-5.201C751.864,44.622,754.603,44.277,757.121,44.316z"/>
                    <path d="M631.697,44.318c5.059-0.067,5.467,0.538,4.336,5.2c-1.471,6.063-7.518,7.059-11.186,5.796
                        c-1.979-0.681-3.328-2.095-3.541-4.336c-0.212-2.234,0.782-3.832,2.662-4.894C626.45,44.682,629.174,44.282,631.697,44.318z"/>
                    <path d="M324.264,40.136c0.511-5.606,3.571-8.851,8.232-8.901c4.79-0.051,7.598,2.975,8.079,8.901
                        C335.226,40.136,329.889,40.136,324.264,40.136z"/>
                    <path d="M162.064,40.072c0.725-5.757,3.789-8.925,8.545-8.836c4.662,0.087,7.396,3.123,7.72,8.835
                        C172.903,40.07,167.485,40.07,162.064,40.072z"/>
                    <path d="M807.586,42.612c-0.041-2.303,0.843-5.299,2.91-7.857c3.781-4.679,10.602-4.678,14.446-0.05
                        c3.751,4.516,3.893,12.701,0.3,17.376c-3.945,5.134-11.499,4.993-15.248-0.284C808.423,49.585,807.598,46.77,807.586,42.612z"/>
                    <path d="M485.378,40.211c1.346-6.545,4.504-9.412,9.426-8.921c4.469,0.446,7.002,3.678,6.879,8.921
                        C496.325,40.211,490.962,40.211,485.378,40.211z"/>
                    <path d="M435.15,44.313c4.385-0.038,4.736,0.379,3.982,4.723c-0.903,5.208-6.683,8.328-11.567,6.194
                        c-1.932-0.844-3.226-2.302-3.386-4.49c-0.151-2.061,0.957-3.487,2.597-4.565C428.557,45.003,431.548,44.344,435.15,44.313z"/>
                    </svg>
                </div>
                <div className="infinity__filter"></div>
                <div className="infinity__wrap">
                    <div className="infinity__svg">
                        <svg id="infinity" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 617.222 303.778">
                            <defs>  
                                {/* repeatCount="indefinite" */}
                                <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%" > 
                                    <stop offset="0%" stopColor="#7A5FFF">
                                        <animate attributeName="stop-color" values="#7A5FFF; #01FF89; #7A5FFF" dur="5s" repeatCount="3"></animate>
                                    </stop>
                                    <stop offset="100%" stopColor="#01FF89">
                                        <animate attributeName="stop-color" values="#01FF89; #7A5FFF; #01FF89" dur="5s" repeatCount="3"></animate>
                                    </stop>
                                </linearGradient> 
                            </defs>
                            <g>
                                <path fill="url('#logo-gradient')"  d="M5.276,150.855c0.062-26.337,8.673-50.454,27.124-70.346c15.193-16.38,30.766-32.534,47.365-47.46
                                    c42.777-38.465,104.702-37.187,146.322,2.525c12.404,11.835,23.897,24.619,36.101,36.671c3.457,3.414,3.552,5.399-0.01,8.707
                                    c-7.851,7.292-15.478,14.846-22.851,22.622c-3.379,3.564-5.365,3.119-8.652-0.205c-13.557-13.713-25.978-28.619-40.982-40.823
                                    c-23.16-18.837-55.87-18.97-78.343,0.916C94.18,78.654,77.723,94.869,62.545,112.039c-22.879,25.882-20.879,61.176,3.866,86.035
                                    c14.04,14.105,28.598,27.721,43.354,41.08c21.793,19.729,53.118,20.724,76.591,2.779c4.362-3.335,8.427-7.116,12.318-11.002
                                    c63.726-63.647,127.325-127.421,191.117-191c39.827-39.694,98.187-45.016,140.99-11.776c22.246,17.276,42.359,37.462,59.704,59.617
                                    c34.011,43.443,28.361,104.355-11.67,142.398c-13.768,13.083-27.598,26.1-41.346,39.205c-38.8,36.986-98.431,38.716-138.471,3.41
                                    c-14.919-13.155-28.626-27.677-43.192-41.248c-3.882-3.617-2.862-5.583,0.273-8.662c7.647-7.511,15.223-15.119,22.4-23.073
                                    c3.357-3.72,5.296-3.334,8.655-0.02c13.08,12.908,26.219,25.777,39.791,38.162c23.342,21.301,57.127,21.639,80.854,0.478
                                    c16.899-15.072,33.968-30.135,48.725-47.338c20.186-23.531,20.155-56.372-0.411-79.669c-14.365-16.273-29.645-31.911-45.821-46.374
                                    c-27.737-24.798-62.342-22.621-89.409,4.416c-64.372,64.3-128.767,128.577-192.973,193.042
                                    c-14.779,14.839-31.385,26.191-51.876,31.349c-33.519,8.438-64.093,1.78-90.241-20.369c-18.655-15.803-36.158-33.108-53.035-50.829
                                    C14.048,203.027,5.42,178.801,5.276,150.855z"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="scroll__down__wrap">
                    {/* <div className="scroll__arrow"></div> */}
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                </div>
            </div>
        </div>
    );
};

export default Infinity;