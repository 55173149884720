import Infinity from '../../Components/Infinity';
import Menu from '../../Components/Menu';
import Products from '../../Components/Products';
import Contact from '../../Components/Contact';

import './style.scss';

const Main = (props) => {
    return (
        <div className="link__main__wrap">
            <Infinity />
            <Menu />
            <Products />
            <Contact />
        </div>
    )
};

export default Main;