import Head from '../Head';
import axios  from 'axios';
import { useEffect, useState } from 'react';
import './style.scss';

const Project = (props) => {

    const [appliance, setAppliance] = useState([]);

    let photos = props.photos;
    let devices;

    useEffect(() => {
        if(props.project && props.project === 'python-api-for-lanner-psp') {
            axios.get('https://link.lannerinc.com/api/psp/models')
            .then((res) => {
                if(res && res.data) {
                    setAppliance(res.data);
                } else {
                    setAppliance([]);
                }
                })
            .catch((err) => {
                setAppliance([]);
            });
        }
    }, []);

    const copyToClipboard = async (e, text) => {
        e.target.innerText = 'Copied!'
        try {
            await navigator.clipboard.writeText(`${text}`);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
        let removeCopied = setTimeout(() => {
            e.target.innerHTML = '<span>Copy</span><span>Installation</span><span>Command</span>'
            clearTimeout(removeCopied);
        }, 1500);
    };
    
    if (props.project == 'python-api-for-lanner-psp') {
        if(!appliance.length) {
            devices = [];
        } else {
            devices = appliance.map((a, i) => {
                return (
                    <div className='link__lanner__psp__appliance' key={`device-${i}`}>
                        <a href={a.product_page} target='_blank' rel="noreferrer noopener" className='link__psp__device'>          
                            <div className="link__project__device">
                                <img src={a.images[0]}/>
                                <span>{a.name}</span>
                            </div>
                        </a>
                        <div className='link__lanner__psp__copy' onClick={(e) => {e.stopPropagation();  copyToClipboard(e, a.one_step_automated_install)}}>
                            <span>Copy</span>
                            <span>Installation</span>
                            <span>Command</span>
                        </div>
                    </div>
                );
            });
        };
    } else {
        if (!photos.length) {
            devices = [];
        } else {
            devices = props.photos.map((p, i) => {
                return (
                    <a href={p.link} target='_blank' rel="noreferrer noopener" key={`device-${i}`}>          
                        <div className="link__project__device">
                            <img src={p.pic}/>
                            <span>{p.name}</span>
                        </div>
                    </a>
                );
            });
        };
    };

    let docLinks = props.docs.map((d, i) => {
        return(
            <div className="link__project__doc__link" key={`item-${i}`}>
                <div className="link__project__doc__dot"></div>
                <a href={`${d.link}`} target="_blank" rel="noreferrer"><span>{d.name}</span></a>
            </div>
        );
    });

    return (
        <div className="link__project__wrap">
            <Head />
            <div className="link__project__content__wrap">
                <div className="link__project__content">
                    <div className="link__project__introduction">
                        <div className="link__project__title">
                            <span>{props.title}</span>
                        </div>
                        {props.project !== 'psp' && (
                            <>
                                <div className="link__project__detail">
                                    <span>Is a software developed by Lanner Software Team to provide you a easy way to set, control and secure your connections.</span>
                                </div>
                                <div className="link__project__appliance">
                                    <div className="link__project__appliance__title">
                                        <span>Appliance</span>
                                    </div>
                                    <div className="link__project__devices__group">
                                        {devices}
                                    </div>
                                </div>
                            </>
                        )}
                        {props.project === 'psp' && (
                            <div className="link__psp__description">
                                <div className="link__psp__paragraph">
                                    <div className="link__psp__title"><span>Information</span></div>
                                    <div className="link__psp__content">
                                        <p>
                                            This package is Lanner's platform software support package for accessing motherboard resource. The supported functions will depend on the design of the platform hardware.
                                        </p>
                                        <p>
                                            Normally, the functions include Watch-Dog-Timer (WDT), General Purpose IO (GPIO), Hardware Monitor (HWM), Software Reset Button (SWR), System/Status LED (SLED), Lanner Gen3 Bypass (LBP), EEPROM (EEP), Redundant Power Supply (PSU) and some external device functions such as the LCD module.
                                        </p>
                                    </div>
                                </div>
                                <div className="link__psp__paragraph">
                                    <div className="link__psp__title"><span>Benefits</span></div>
                                    <div className="link__psp__content">
                                        <p><b>- Faster Time to Market</b></p>
                                        <p>Lanner’s API helps developers to write applications to control the hardware without knowing the hardware specs of the chipsets and driver architecture.</p>
                                        <p><b>- Reduced Project Effort</b></p>
                                        <p>Accessing the API, developers are being given a more efficient way to utilize chipsets, communication bus, and physical I/Os. In addition, Lanner PSP also provides corresponding tools to prevent unexpected downtime causing from environment setup matters.</p>
                                        <p><b>- Enhances Lanner Platform Reliability</b></p>
                                        <p>Lanner PSP is released after a series of reliability tests and security validations which combines manufacturing test sequences to enhance complete system reliability.</p>
                                        <p><b>- Flexible Upgrade Possibilities</b></p>
                                        <p>Considering customer’s application maintenance and upgrade tasks, Lanner PSP is designed to be flexible to update/upgrade to the module level. The simple re-initialization process will bring up the updated and upgraded modules to be functional.</p>
                                        <p><b>- Backward compatibility</b></p>
                                        <p>Lanner grants the responsibility to control API backward support, allowing customers to worry less about the development of new products.</p>
                                        <p><b>- Functions</b></p>
                                        <p>Normally, the functions include Watch-Dog-Timer (WDT), General Purpose IO (GPIO), Hardware Monitor (HWM), Software Reset Button (SWR), System/Status LED (SLED), Lanner Gen3 Bypass (LBP), EEPROM (EEP), Redundant Power Supply (PSU) and some external device functions such as the LCD module.</p>
                                    </div>
                                </div>
                                <div className="link__psp__paragraph">
                                    <div className="link__psp__title"><span>History</span></div>
                                    <div className="link__psp__content">
                                        <p><b>SDK 1.0</b></p>
                                        <ul>
                                            <li>Publication : 2018/3/23</li>
                                            <li>Access Mode : direct IO access mode</li>
                                            <li>Open Source : None, only binary files</li>
                                            <li>Stage : Maintain Only</li>
                                            <li>direct IO access mode, not open source</li>
                                        </ul>
                                        <p><b>PSP 2.0</b></p>
                                        <ul>
                                            <li>Publication : 2019/4/1</li>
                                            <li>Access Mode : Driver access mode</li>
                                            <li>Open Source : SDK library binary files, Driver and IO library is opened</li>
                                            <li>Stage : Maintain Only</li>
                                            <li>driver access mode and open source both driver and library, but SDK API not including yet</li>
                                        </ul>
                                        <p><b>PSP 2.1</b></p>
                                        <ul>
                                            <li>Publication : 2019/7/1</li>
                                            <li>Access Mode : Driver access mode</li>
                                            <li>Open Source : all source is opened</li>
                                            <li>Stage : Maintain Only</li>
                                        </ul>
                                        <p><b>PSP 2.2</b></p>
                                        <ul>
                                            <li>Publication : 2020/3/1</li>
                                            <li>Access Mode : Driver access mode</li>
                                            <li>Open Source : all source is opened</li>
                                            <li>Stage : Maintain Only</li>
                                            <li>Note: 2.2 same as 2.1 with driver sysfs support</li>
                                        </ul>
                                        <p><b>PSP 2.3</b></p>
                                        <ul>
                                            <li>Publication : 2020/7/23</li>
                                            <li>Access Mode : Driver access mode</li>
                                            <li>Open Source : all source is opened</li>
                                            <li>Stage : NPI development</li>
                                            <li>New Function: add IPMI API group and build-in critical value</li>
                                        </ul>
                                        <p><b>PSP 3.0</b></p>
                                        <ul>
                                            <li>Alpha: 2021/06/18 (NCA-5530)</li>
                                            <li>Access Mode : Driver access mode</li>
                                            <li>Open Source : all source is opened</li>
                                            <li>Stage : NPI development</li>
                                            <li>New Function: 
                                                <ol>
                                                    <li>Common Weakness Enumeration compatibility Level 3</li>
                                                    <li>static code analysis by cppcheck ready</li>
                                                </ol>
                                            </li>
                                        </ul>
                                        <p><b>SDK1.0 & PSP Differentiation</b></p>
                                        <div className="link__psp__diff__table">
                                            <div className="link__psp__diff__table__head">
                                                <div className="link__psp__diff__table__head__item"><span>Functions</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>SDK 1.0</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>PSP 2.0</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>PSP 2.1</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>PSP 2.2</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>PSP 2.3</span></div>
                                                <div className="link__psp__diff__table__head__item"><span>PSP 3.0 Alpha</span></div>
                                            </div>
                                            <div className="link__psp__diff__table__body">
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Access Method</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Direct IO</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Driver</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Driver</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Driver</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Driver</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Driver</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Functions Modularization</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Binary Pre-build</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Both x64 and x32</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Both x64 and x32</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Open Source</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Open without SDK</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>All</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>All</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>All</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>All</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Dynamic Libraries</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Dynamic Mode Utilities</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Option</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Option</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Option</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Option</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Static Libraries</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Static Mode Utilities</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Default</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Default</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Default</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Default</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Driver Sysfs Support</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Option</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>IPMI API Group</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>Build-in Critical Value</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Yes</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>CWE Compatibility</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Level 3</span></div>
                                                </div>
                                                <div className="link__psp__diff__table__body__line">
                                                    <div className="link__psp__diff__table__body__item"><span>cppcheck</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>No</span></div>
                                                    <div className="link__psp__diff__table__body__item"><span>Ready</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        )}
                    </div>
                    <div className="link__project__half__line" style={{background: `${props.color}`}}></div>
                    <div className="link__project__documents">
                        <div className="link__project__title">
                            <span>Relative Links</span>
                        </div>
                        <div className="link__project__links">
                            {docLinks}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Project;