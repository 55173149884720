import { Swiper, SwiperSlide } from "swiper/react";
import LEC7242 from '../../images/lec7242.png';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Mousewheel, Keyboard } from "swiper";
import './style.scss';

const Products = (props) => {

    return (
        <div className="link__products__wrap">
            <div className="link__products__title">
                <span>FEATURED PRODUCTS</span>
            </div>
            <div className="link__products__swiper">
                <Swiper
                    cssMode={true}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{clickable: true}}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Pagination, Navigation, Mousewheel, Keyboard]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="link__product__card">
                            <div className="link__product__card__bg"></div>
                            <div className="link__product__card__content">
                                <div className="link__product__image">
                                    <div className="LEC-7242"></div>
                                    <span className="link__product__more"><a href="https://www.lannerinc.com/tw/products-tw/intelligent-edge-appliances/embedded-platform/lec-7242" target="_blank" rel="noreferrer">learn more...</a></span>
                                </div>
                                <div className="link__product__line"></div>
                                <div className="link__product__detail">
                                    <span className="link__product__name">LEC - 7242</span>
                                    <span className="link__product__description">Industrial wireless gateway with Intel® Apollo Lake CPU and FCC/CE/PTCRB certification for LTE or Wifi.</span>
                                    <ul>
                                        <li><span>Intel® Apollo Lake CPU</span></li>
                                        <li> <span>2x GbE RJ45, 1x RS232/422/485 COM Port</span></li>
                                        <li><span>2x USB 3.0, 1x Display Port, 1x HDMI</span></li>
                                        <li><span>1x M.2 3042 B+M Key Socket w/ Dual SIM</span></li>
                                        <li><span>1x Mini-PCIe Socket w/ Dual SIM</span></li>
                                        <li><span>TPM 2.0 Support</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="link__product__card">
                            <div className="link__product__card__bg"></div>
                            <div className="link__product__card__content content__empty">
                                <span>Coming soon...</span>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default Products;