
import './style.scss';

const PageNotFound = (props) => {

    return (
        <div className="link__page__404">
            <div className="link__404__text">
                <span>404</span>
                <span>|</span>
                <span>Page not found.</span>
            </div>
        </div>
    );
};

export default PageNotFound;