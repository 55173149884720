import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Link } from "react-router-dom";

import './style.scss'

const Menu = (props) => {
    const particlesInit = async (main) => {
        console.log(main);
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
    console.log(container);
    };

    return (
        <>
        <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                    color: {
                        value: "#000",
                    },
                    },
                    fpsLimit: 120,
                    interactivity: {
                    events: {
                        onClick: {
                            enable: false
                        },
                        onHover: {
                            enable: false,
                            // mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                        quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 10,
                        },
                    },
                    },
                    particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 200,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 500,
                        },
                        value: 15,
                    },
                    opacity: {
                        value: 0.3,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                    },
                    detectRetina: true,
                }}
            />
            <div className="link__menu__wrap">
                <div className="link__menu__title">
                    <span>OUR SOLUTIONS</span>
                </div>
                <div className="link__menu__group">
                    <Link to={'/manageability'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="ma">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Manageability</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/reliability'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="re">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Reliability</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/security'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="se">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Security</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/performance'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="pe">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Performance</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/development'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="de">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Development</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={'/connectivity'}>
                        <div className="link__menu__card__container">
                            <div className="link__menu__card__wrap" id="cm">
                                <div className="link__menu__card__border">
                                    <div className="link__menu__card__content">
                                        <span>Connectivity</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
        
    )
};

export default Menu;